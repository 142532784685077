:root {
  --rsbs-max-w: 576px;
  --rsbs-ml: auto;
  --rsbs-mr: auto;
}

.dark {
  --rsbs-bg: #5227a7;
}

.light {
  --rsbs-bg: #f7f7f7;
}

[data-rsbs-backdrop] {
  backdrop-filter: blur(2px);
}

[data-rsbs-overlay] {
  max-height: 97vh;
}

[data-rsbs-content] {
  min-height: 100%;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}
