body .ant-picker {
  @apply rounded-lg bg-pt-purple-darker text-white border-0 text-xs px-4 py-0;
  border: 2px solid #291357;
  cursor: pointer;
}

body .ant-picker-input > input {
  @apply font-semibold text-xxs text-white py-2;
}

@screen xs {
  body .ant-picker-input > input {
    @apply py-3 text-lg;
  }
}

body .ant-picker-input > input:hover {
  cursor: pointer !important;
}

body .anticon svg {
  fill: rgb(17, 107, 204);
}

body .ant-picker-clear {
  display: none;
}

body .ant-picker:hover,
body .ant-picker-focused {
  border: 2px solid #35f0d0;
}
